<template>
<v-app>
  <left-drawer/>
<!--   <left-drawer/>
  <right-drawer/> -->
  <v-app-bar dense app color="green lighten-1">
    <v-app-bar-nav-icon v-if="!usaLeft2" @click="openLeftDrawer"></v-app-bar-nav-icon>
    <v-app-bar-title>Ubisell Data Platform </v-app-bar-title>
    <v-spacer></v-spacer>
    <v-btn icon>
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
  </v-app-bar>
  <v-main>
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </v-main>

  <v-footer app>
    <!-- -->
  </v-footer>
</v-app>
</template>

<script>
  import LeftDrawer from './views/drawer/leftDrawer'
/* import LeftDrawer from './views/drawer/leftDrawer2'
import RightDrawer from './views/drawer/rightDrawer' */

export default {
  name: 'App',
  components: {
    LeftDrawer
  },
/*   components: {
    LeftDrawer,
    RightDrawer
  }, */

  data: () => ({
    usaLeft2: false
  }),
  methods: {
    openLeftDrawer() {
      this.$store.commit('SET_LEFT_DRAWER', true)
    }
  }
};
</script>
