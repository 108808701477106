'use strict'
require('dotenv').config();
// const fetch = require('node-fetch')
const service = process.env.VUE_APP_service_licenze || ''

const fetchTimeout = (url, ms, ac, { signal, ...options } = {}) => {
  const promise = fetch(url, { signal: signal, ...options });
  const timeout = setTimeout(() => ac.abort(), ms);
  return promise.finally(() => {
    clearTimeout(timeout)
  });
};

exports.getToken = async function(apiKey, base, isService) {
  // construire la audience in base ai pacchetti necessari
  const audience = 'base'

  const controller = new AbortController();
  var result
  try {
    const timeout = 3000
    result = await fetchTimeout(`${service}/jwt?id=${base}&key=${apiKey}&aud=${audience}&service=${isService}&ver=v2`, timeout, controller, { // 5000
      signal: controller.signal,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    const token = await result.json()
    return token
  } catch (error) {
    if (result && result.status === 403) {
      return 'Licenza scaduta'
    } else {
      return ''
    }
  }
}

exports.login = async function (utente) {
  try {
    const response = await fetch(`${service}/user/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(utente)
    })
    if (response.ok) {
      return await response.json()
    } else {
      if (response.status === 401) {
        return { error: true, message: 'Credenziali non corrette' }
      } else {
        return { error: true, message: 'Errore di rete. Contattare l\'amministratore' }
      }
    }
  } catch (err) {
    console.log('errore login', err)
  }
}

/* export default {
  cicleSync,
  getToken,
  verifyToken,
  login,
  getApiKey,
  getShortApiKey,
  creaLicenza,
  aggiornaLicenza,
  listCustomerDatabase,
  listCustomerDatabaseWithInfo,
  creaDbVuoti,
  copiaDbEsistenti,
  removeDatabase,
  removeAllDatabases,
  createBaseSettings,
  copyDatabase,
  aggiornaImpostazioniCliente,
  sanitizeDb,
  correggeMovimentiScontrino,
  eliminaProdottiObsoleti,
  aggiungeCategoriaDefault,
  getElencoProgetti
}
 */