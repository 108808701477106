import Vue from 'vue'
import VueRouter from 'vue-router'
import _ from 'lodash'
import store from '../store'
import { Role } from '@/helpers/role';
import { Audience } from '@/helpers/audience';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeAuth',
    component: () => import(/* webpackChunkName: "homeauth" */ '../views/dashboard'),
    meta: { authorize: [Audience.Dashboard] }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home')
  },    
  { // deprecata --> non devo passare le informazioni della licenza su http
    path: '/:key/:module/:base',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard'),
    meta: { authorize: [Audience.Dashboard] }
  },
  {
    path: '/register/:key/:module/:base',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/dashboard'),
    meta: { authorize: [Audience.Dashboard] }
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login')
  },  
  {
    path: '/v-stampe',
    name: 'Stampe',
    component: () => import(/* webpackChunkName: "stampe" */ '../views/stampe'),
    meta: { authorize: [Audience.Pdf] }
  },
 //  {
 //    path: '/pdf',
 //    name: 'Pdf',
 //    component: () => import(/* webpackChunkName: "pdf" */ '../views/pdfViewer/viewer.vue'),
 //    props: route => ({ source: route.params.source })
 //  },    
  {
    path: '/licenza',
    name: 'Licenza',
    component: () => import(/* webpackChunkName: "licenza" */ '../views/licenza/licenzaScaduta.vue')
  },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const { authorize } = to.meta;
  let currentDevice = store.getters['mobile/device']
  if (authorize) {
    if (!currentDevice || to.name === 'Register') {
      await store.dispatch('mobile/deviceLogin', { apiKey: to.params.key, moduleKey: to.params.module, base: to.params.base, device: to.params.device, service: to.query.service === 'true'})
      currentDevice = store.getters['mobile/device']
    } else {
      await store.dispatch('mobile/testTokenExpiration')
    }
    if (!currentDevice || (authorize.length && !_.intersection(authorize, currentDevice.audience).length)) {
      return next({ path: '/home' });
    }
  }

  next()
})

export default router
